import React from "react";
import "../styles/paintingCard.css";
import data from "../assets/paintings.json";

const PaintingCard = () => {
  return (
    <section className="card-section">
      <div className="card-container">
        {data.map((item) => (
          <div key={item.id} id={item.id} className="card">
            <img src={item.src} alt={item.name} className="card-image" />
            <div className="card-content">
              <h3 className="card-title">{item.name}</h3>
              <p className="card-type">{item.type}</p>
              <p className="card-size">{item.size}</p>
              <button
                className="card-button"
                onClick={() =>
                  (window.location.href = "mailto:/Contact.niev@gmail.com")
                }
              >
                Email for Price
              </button>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PaintingCard;
