import React, { useEffect, useState } from "react";
import "../styles/home.css";
import Loader from "../components/loader";
import About from "../components/aboutMe";
import PaintingsGrid from "../components/paintingsGrid";

const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <Loader />;
  }
  //    newsletter    header    popup

  return (
    <div id="home" className="home">
      {/* Hero Section */}
      <div
        className="hero-image"
        style={{
          backgroundImage: `url(./paintings/Northern-Lights.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "16rem",
          width: "100%",
        }}
      ></div>

      {/* About the Artist Section */}
      <About />

      {/* painting-grid */}
      <PaintingsGrid />
    </div>
  );
};

export default Home;
