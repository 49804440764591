import React from "react";
import "../styles/aboutMe.css";
import portrait from "../assets/About-me.jpeg";

const About = () => {
  return (
    <section className="aboutMe-section">
      <div className="aboutMe-title">About Me</div>
      <div className="content">
        <div className="aboutMe-content">
          <p>
            My name is Niev. I am 4 years old and love to paint. I have been
            painting since I was 15 months old and love being among colors. Art
            brings immense joy to me and I would like to share this joy with all
            of you through my art. My dream is to take my art all over the world
          </p>
          <a href="/about" className="more-button">
            More About Niev
          </a>
        </div>
        <div className="aboutMe-image">
          <img src={portrait} alt="Artist Portfolio" />
        </div>
      </div>
    </section>
  );
};

export default About;
