import React, { useEffect, useState } from "react";
import "../styles/painting.css";
import Loader from "../components/loader";
import PaintingCard from "../components/paintingCard";

const Paintings = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="paintings">
      {/* Hero Section */}
      <div
        className="hero-image"
        style={{
          backgroundImage: `url("../paintings/Northern-Lights.png")`,
          backgroundSize: "cover",
          border: "2px",
          backgroundPosition: "center",
          height: "16rem",
          width: "100%",
        }}
      ></div>

      <div className="painting-title">
        My paintings are a reflection of how I perceive the world around me,
        capturing the beauty and essence of nature through my eyes, using mostly
        acrylic paints.
      </div>

      {/* Paintings Card */}
      <PaintingCard />
    </div>
  );
};

export default Paintings;
