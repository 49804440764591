import React from "react";
import "../styles/paintingsGrid.css";
import paintings from "../assets/paintings.json";

const PaintingsGrid = () => {
  return (
    <section className="paintings-section">
      <div className="paintings-title">My Paintings</div>
      <div className="paintings-grid">
        {paintings.map((painting) => (
          <a
            key={painting.id}
            href={`/paintings/#${painting.id}`}
            className="grid-item"
          >
            <img src={painting.src} alt={`Painting ${painting.id}`} />
          </a>
        ))}
      </div>
      <div className="to-more">
        <a href="/paintings">Click for more Paintings</a>
      </div>
    </section>
  );
};

export default PaintingsGrid;
