import React, { useEffect, useState } from "react";
import Loader from "../components/loader";
import "../styles/about.css";
import portrait from "../assets/About-me.jpeg";

const About = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="about">
      {/* Hero Section */}
      <div
        className="hero-image"
        style={{
          backgroundImage: `url("./paintings/Northern-Lights.png")`,
          backgroundSize: "cover",
          border: "2px",
          backgroundPosition: "center",
          height: "16rem",
          width: "100%",
        }}
      ></div>

      {/*About section */}
      <section className="about-section">
        <div className="about-title">About Niev</div>
        <div className="about-content">
          <div className="about-image">
            <img src={portrait} alt="Artist Portfolio" />
          </div>
          <div className="about-info">
            <p>
              My name is Niev, and I am a passionate 4-year-old artist who has
              been captivated by the world of colors since I was just 15 months
              old. Painting fills my heart with joy, and I feel truly alive when
              immersed in the creative process. Every aspect of creating art,
              from selecting the perfect canvas to mixing vibrant colors and
              even cleaning my brushes, brings me immense happiness.
            </p>
            <p>
              I am a self-taught artist who began exploring the beauty of colors
              as a toddler. Art is my way of sharing joy with the world, and it
              has become my dream to see my creations inspire people across the
              globe. My paintings have already found homes with collectors from
              the USA to Singapore, sparking interest and admiration from art
              enthusiasts worldwide.
            </p>
            <p>
              Through my artwork, I hope to continue spreading the joy and
              wonder that I feel every time I paint, connecting hearts and minds
              through the language of colors.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
