import React from "react";
import "../styles/newsLetter-Footer.css";
import newsletterbg from "../assets/newsLetter.png";
import ScrollToTopButton from "../components/scroll-to-top";
import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";

const NewsletterFooter = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    // setResult("Sending....");
    const formData = new FormData(event.target);

    formData.append("access_key", "56682b6c-79d1-4922-bc98-f3c2fd44725d");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData,
    });

    const data = await response.json();

    if (data.success) {
      // setResult("Form Submitted Successfully");
      event.target.reset();
    } else {
      console.log("Error", data);
      // setResult(data.message);
    }
  };

  return (
    <footer className="newsletter-footer">
      {/* Newsletter Section */}
      <div className="newsletter-section">
        <span>Subscribe to Niev’s Newsletter</span>
        <p>
          Stay informed about the latest work and events. Subscribe to get early
          access to Niev’s artwork.
        </p>
        <form
          className="newsletter-form"
          style={{
            backgroundImage: `url(${newsletterbg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100%",
            width: "100%",
          }}
          onSubmit={onSubmit}
        >
          <div className="form-row">
            <input
              type="text"
              placeholder="First Name"
              className="form-input"
              name="name"
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              className="form-input"
              name="last-name"
              required
            />
          </div>

          <div className="form-row">
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="form-input email"
              required
            />
            <button type="submit" className="form-button">
              Subscribe
            </button>
          </div>

          <div className="privacy-policy">
            <p>
              By signing up you agree to our{" "}
              <a href="mailto:/Contact.niev@gmail.com">privacy policy</a>.
            </p>
          </div>
        </form>
      </div>

      {/* Footer Section */}
      <div className="footer-links">
        <div className="footer-column">
          Get In Touch
          <p>
            <a href="mailto:/Contact.niev@gmail.com">contact.niev@gmail.com</a>
          </p>
          <div className="social-icons">
            <a href="https://www.instagram.com/niev_art/" className="icon">
              {/*🌐*/} <FaInstagram />
            </a>
            <a href="mailto:/Contact.niev@gmail.com" className="icon">
              {/*📸*/} <FaYoutube />
            </a>
            <a href="mailto:/Contact.niev@gmail.com" className="icon">
              {/*📱*/} <FaFacebook />
            </a>
          </div>
        </div>
        <div className="footer-column">
          <a href="/home">Home</a>
          <a href="/paintings">Paintings</a>
          <a href="/about">About Me</a>
        </div>
        <div className="footer-column">
          <a href="mailto:/Contact.niev@gmail.com">T&Cs</a>
          <a href="mailto:/Contact.niev@gmail.com">Privacy Policy</a>
        </div>
      </div>
      <ScrollToTopButton />
      <div className="footer-bottom">
        <p>© 2024 Niev Art. All Rights Reserved</p>
      </div>
    </footer>
  );
};

export default NewsletterFooter;
