import React, { useState } from "react";
import "../styles/header.css";
import logo from "../assets/final-logo.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Header = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <nav className="nav-links">
        <a href="/home">Home</a>
        <a href="/about"> About</a>
        <a href="/paintings">Paintings</a>
      </nav>

      {/* Hamburger Menu (For Mobile View) */}
      <button
        className="hamburger-icon"
        onClick={toggleSidebar}
        aria-label="Toggle Sidebar"
      >
        {isSidebarOpen ? "" : <FaBars />}
      </button>

      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <button
          className="hamburger-icon"
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </button>
        <a href="/home" onClick={toggleSidebar}>
          Home
        </a>
        <a href="/about" onClick={toggleSidebar}>
          About
        </a>
        <a href="/paintings" onClick={toggleSidebar}>
          Paintings
        </a>
      </div>
    </header>
  );
};

export default Header;
